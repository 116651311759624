import React from "react"

import Layout from "../components/layout"
import Homegrid from "../components/homegrid"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Homegrid />
  </Layout>
)

export default IndexPage
