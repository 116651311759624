import React from "react"

function Homegrid() {
  return (
    <article className="home-grid">
      <blockquote className="home-grid__blockquote">
        atomic design group
      </blockquote>
      <div className="home-grid__circle"></div>
      <div className="home-grid__circle"></div>
      <div className="home-grid__circle"></div>
      <div className="home-grid__circle"></div>
      <div className="home-grid__circle"></div>
      <div className="home-grid__circle"></div>
      <div className="home-grid__circle"></div>
      <div className="home-grid__circle"></div>
      <span className="home-grid__span"></span>
      <span className="home-grid__span"></span>
      <div className="home-grid__small"></div>
      <div className="home-grid__small"></div>
    </article>
  )
}

export default Homegrid
